/**
 * Webpack has bug for import loop, which is not the same behavior as ES module.
 * When util.js imports the TreeNode for tree generate will cause treeContextTypes be empty.
 */
import { inject, computed, defineComponent, provide } from 'vue';
const TreeContextKey = Symbol('TreeContextKey');
export const TreeContext = defineComponent({
    name: 'TreeContext',
    props: {
        value: { type: Object },
    },
    setup(props, { slots }) {
        provide(TreeContextKey, computed(() => props.value));
        return () => { var _a; return (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots); };
    },
});
export const useInjectTreeContext = () => {
    return inject(TreeContextKey, computed(() => ({})));
};
