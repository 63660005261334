var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { onUpdated, getCurrentInstance, defineComponent, nextTick, Transition as T, TransitionGroup as TG, } from 'vue';
export const getTransitionProps = (transitionName, opt = {}) => {
    if (process.env.NODE_ENV === 'test') {
        return opt;
    }
    const transitionProps = transitionName
        ? Object.assign({ name: transitionName, appear: true, 
            // type: 'animation',
            // appearFromClass: `${transitionName}-appear ${transitionName}-appear-prepare`,
            // appearActiveClass: `antdv-base-transtion`,
            // appearToClass: `${transitionName}-appear ${transitionName}-appear-active`,
            enterFromClass: `${transitionName}-enter ${transitionName}-enter-prepare`, enterActiveClass: `${transitionName}-enter ${transitionName}-enter-prepare`, enterToClass: `${transitionName}-enter ${transitionName}-enter-active`, leaveFromClass: ` ${transitionName}-leave`, leaveActiveClass: `${transitionName}-leave`, leaveToClass: `${transitionName}-leave ${transitionName}-leave-active` }, opt) : Object.assign({ css: false }, opt);
    return transitionProps;
};
export const getTransitionGroupProps = (transitionName, opt = {}) => {
    const transitionProps = transitionName
        ? Object.assign({ name: transitionName, appear: true, 
            // appearFromClass: `${transitionName}-appear ${transitionName}-appear-prepare`,
            appearActiveClass: `${transitionName}`, appearToClass: `${transitionName}-appear ${transitionName}-appear-active`, enterFromClass: `${transitionName}-appear ${transitionName}-enter ${transitionName}-appear-prepare ${transitionName}-enter-prepare`, enterActiveClass: `${transitionName}`, enterToClass: `${transitionName}-enter ${transitionName}-appear ${transitionName}-appear-active ${transitionName}-enter-active`, leaveActiveClass: `${transitionName} ${transitionName}-leave`, leaveToClass: `${transitionName}-leave-active` }, opt) : Object.assign({ css: false }, opt);
    return transitionProps;
};
let Transition = T;
let TransitionGroup = TG;
if (process.env.NODE_ENV === 'test') {
    let warn = true;
    Transition = defineComponent({
        name: 'TransitionForTest',
        inheritAttrs: false,
        setup(_props, { slots, attrs }) {
            const instance = getCurrentInstance();
            if (warn) {
                console.warn('application runing at test env, you should build use production env');
                warn = false;
            }
            onUpdated(() => {
                const child = instance.subTree.children[0];
                if (child && child.dirs && child.dirs[0]) {
                    const value = child.dirs[0].value;
                    const oldValue = child.dirs[0].oldValue;
                    if (!value && value !== oldValue) {
                        nextTick(() => {
                            if (attrs.onAfterLeave) {
                                attrs.onAfterLeave(instance.vnode.el);
                            }
                        });
                    }
                }
            });
            return () => {
                var _a;
                return (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots);
            };
        },
    });
    TransitionGroup = defineComponent({
        name: 'TransitionGroupForTest',
        inheritAttrs: false,
        props: ['tag', 'class'],
        setup(props, { slots }) {
            return () => {
                var _a;
                const { tag: Tag } = props, rest = __rest(props, ["tag"]);
                const children = ((_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)) || [];
                if (Tag) {
                    return <Tag {...rest}>{children}</Tag>;
                }
                else {
                    return children;
                }
            };
        },
    });
}
// ================== Collapse Motion ==================
const getCollapsedHeight = () => ({ height: 0, opacity: 0 });
const getRealHeight = node => ({
    height: `${node.scrollHeight}px`,
    opacity: 1,
});
const getCurrentHeight = (node) => ({ height: `${node.offsetHeight}px` });
const collapseMotion = (name = 'ant-motion-collapse', style, className) => {
    return {
        name,
        appear: true,
        css: true,
        onBeforeEnter: node => {
            className.value = name;
            style.value = getCollapsedHeight(node);
        },
        onEnter: node => {
            nextTick(() => {
                style.value = getRealHeight(node);
            });
        },
        onAfterEnter: () => {
            className.value = '';
            style.value = {};
        },
        onBeforeLeave: node => {
            className.value = name;
            style.value = getCurrentHeight(node);
        },
        onLeave: node => {
            setTimeout(() => {
                style.value = getCollapsedHeight(node);
            });
        },
        onAfterLeave: () => {
            className.value = '';
            style.value = {};
        },
    };
};
const getTransitionName = (rootPrefixCls, motion, transitionName) => {
    if (transitionName !== undefined) {
        return transitionName;
    }
    return `${rootPrefixCls}-${motion}`;
};
export { Transition, TransitionGroup, collapseMotion, getTransitionName };
export default Transition;
