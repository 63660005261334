import { defineComponent } from 'vue';
import ImageInternal from '../vc-image';
import { imageProps } from '../vc-image/src/Image';
import useConfigInject from '../_util/hooks/useConfigInject';
import PreviewGroup from './PreviewGroup';
const Image = defineComponent({
    name: 'AImage',
    inheritAttrs: false,
    props: imageProps(),
    setup(props, { slots, attrs }) {
        const { prefixCls } = useConfigInject('image', props);
        return () => {
            return (<ImageInternal {...Object.assign(Object.assign(Object.assign({}, attrs), props), { prefixCls: prefixCls.value })} v-slots={slots}></ImageInternal>);
        };
    },
});
export { imageProps };
Image.PreviewGroup = PreviewGroup;
Image.install = function (app) {
    app.component(Image.name, Image);
    app.component(Image.PreviewGroup.name, Image.PreviewGroup);
    return app;
};
export { PreviewGroup as ImagePreviewGroup };
export default Image;
