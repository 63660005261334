var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Trigger from '../vc-trigger';
import PropTypes from '../_util/vue-types';
import classNames from '../_util/classNames';
import { computed, ref, defineComponent } from 'vue';
const getBuiltInPlacements = (adjustX) => {
    return {
        bottomLeft: {
            points: ['tl', 'bl'],
            offset: [0, 4],
            overflow: {
                adjustX,
                adjustY: 1,
            },
        },
        bottomRight: {
            points: ['tr', 'br'],
            offset: [0, 4],
            overflow: {
                adjustX,
                adjustY: 1,
            },
        },
        topLeft: {
            points: ['bl', 'tl'],
            offset: [0, -4],
            overflow: {
                adjustX,
                adjustY: 1,
            },
        },
        topRight: {
            points: ['br', 'tr'],
            offset: [0, -4],
            overflow: {
                adjustX,
                adjustY: 1,
            },
        },
    };
};
const getAdjustX = (adjustXDependencies) => {
    const { autoAdjustOverflow, dropdownMatchSelectWidth } = adjustXDependencies;
    if (!!autoAdjustOverflow)
        return 1;
    // Enable horizontal overflow auto-adjustment when a custom dropdown width is provided
    return typeof dropdownMatchSelectWidth !== 'number' ? 0 : 1;
};
const SelectTrigger = defineComponent({
    name: 'SelectTrigger',
    inheritAttrs: false,
    props: {
        dropdownAlign: PropTypes.object,
        visible: { type: Boolean, default: undefined },
        disabled: { type: Boolean, default: undefined },
        dropdownClassName: String,
        dropdownStyle: PropTypes.object,
        placement: String,
        empty: { type: Boolean, default: undefined },
        autoAdjustOverflow: { type: Boolean, default: undefined },
        prefixCls: String,
        popupClassName: String,
        animation: String,
        transitionName: String,
        getPopupContainer: Function,
        dropdownRender: Function,
        containerWidth: Number,
        dropdownMatchSelectWidth: PropTypes.oneOfType([Number, Boolean]).def(true),
        popupElement: PropTypes.any,
        direction: String,
        getTriggerDOMNode: Function,
        onPopupVisibleChange: Function,
        onPopupMouseEnter: Function,
    },
    setup(props, { slots, attrs, expose }) {
        const builtInPlacements = computed(() => {
            const { autoAdjustOverflow, dropdownMatchSelectWidth } = props;
            return getBuiltInPlacements(getAdjustX({
                autoAdjustOverflow,
                dropdownMatchSelectWidth,
            }));
        });
        const popupRef = ref();
        expose({
            getPopupElement: () => {
                return popupRef.value;
            },
        });
        return () => {
            const _a = Object.assign(Object.assign({}, props), attrs), { empty = false } = _a, restProps = __rest(_a, ["empty"]);
            const { visible, dropdownAlign, prefixCls, popupElement, dropdownClassName, dropdownStyle, direction = 'ltr', placement, dropdownMatchSelectWidth, containerWidth, dropdownRender, animation, transitionName, getPopupContainer, getTriggerDOMNode, onPopupVisibleChange, onPopupMouseEnter, } = restProps;
            const dropdownPrefixCls = `${prefixCls}-dropdown`;
            let popupNode = popupElement;
            if (dropdownRender) {
                popupNode = dropdownRender({ menuNode: popupElement, props });
            }
            const mergedTransitionName = animation ? `${dropdownPrefixCls}-${animation}` : transitionName;
            const popupStyle = Object.assign({ minWidth: `${containerWidth}px` }, dropdownStyle);
            if (typeof dropdownMatchSelectWidth === 'number') {
                popupStyle.width = `${dropdownMatchSelectWidth}px`;
            }
            else if (dropdownMatchSelectWidth) {
                popupStyle.width = `${containerWidth}px`;
            }
            return (<Trigger {...props} showAction={onPopupVisibleChange ? ['click'] : []} hideAction={onPopupVisibleChange ? ['click'] : []} popupPlacement={placement || (direction === 'rtl' ? 'bottomRight' : 'bottomLeft')} builtinPlacements={builtInPlacements.value} prefixCls={dropdownPrefixCls} popupTransitionName={mergedTransitionName} popupAlign={dropdownAlign} popupVisible={visible} getPopupContainer={getPopupContainer} popupClassName={classNames(dropdownClassName, {
                    [`${dropdownPrefixCls}-empty`]: empty,
                })} popupStyle={popupStyle} getTriggerDOMNode={getTriggerDOMNode} onPopupVisibleChange={onPopupVisibleChange} v-slots={{
                    default: slots.default,
                    popup: () => (<div ref={popupRef} onMouseenter={onPopupMouseEnter}>
                {popupNode}
              </div>),
                }}></Trigger>);
        };
    },
});
export default SelectTrigger;
