import { computed, watch, defineComponent, onMounted, ref } from 'vue';
import VcTooltip from '../vc-tooltip';
import classNames from '../_util/classNames';
import PropTypes from '../_util/vue-types';
import { PresetColorTypes } from '../_util/colors';
import warning from '../_util/warning';
import { getStyle, filterEmpty, isValidElement, initDefaultProps } from '../_util/props-util';
import { cloneElement } from '../_util/vnode';
import abstractTooltipProps from './abstractTooltipProps';
import useConfigInject from '../_util/hooks/useConfigInject';
import getPlacements from './placements';
import firstNotUndefined from '../_util/firstNotUndefined';
import raf from '../_util/raf';
const splitObject = (obj, keys) => {
    const picked = {};
    const omitted = Object.assign({}, obj);
    keys.forEach(key => {
        if (obj && key in obj) {
            picked[key] = obj[key];
            delete omitted[key];
        }
    });
    return { picked, omitted };
};
const PresetColorRegex = new RegExp(`^(${PresetColorTypes.join('|')})(-inverse)?$`);
export const tooltipProps = () => (Object.assign(Object.assign({}, abstractTooltipProps()), { title: PropTypes.any }));
export const tooltipDefaultProps = () => ({
    trigger: 'hover',
    transitionName: 'zoom-big-fast',
    align: {},
    placement: 'top',
    mouseEnterDelay: 0.1,
    mouseLeaveDelay: 0.1,
    arrowPointAtCenter: false,
    autoAdjustOverflow: true,
});
export default defineComponent({
    name: 'ATooltip',
    inheritAttrs: false,
    props: initDefaultProps(tooltipProps(), {
        trigger: 'hover',
        transitionName: 'zoom-big-fast',
        align: {},
        placement: 'top',
        mouseEnterDelay: 0.1,
        mouseLeaveDelay: 0.1,
        arrowPointAtCenter: false,
        autoAdjustOverflow: true,
    }),
    slots: ['title'],
    // emits: ['update:visible', 'visibleChange'],
    setup(props, { slots, emit, attrs, expose }) {
        const { prefixCls, getTargetContainer } = useConfigInject('tooltip', props);
        const visible = ref(firstNotUndefined([props.visible, props.defaultVisible]));
        const tooltip = ref();
        onMounted(() => {
            warning(props.defaultVisible === undefined, 'Tooltip', `'defaultVisible' is deprecated, please use 'v-model:visible'`);
        });
        let rafId;
        watch(() => props.visible, val => {
            raf.cancel(rafId);
            rafId = raf(() => {
                visible.value = !!val;
            });
        });
        const isNoTitle = () => {
            var _a;
            const title = (_a = props.title) !== null && _a !== void 0 ? _a : slots.title;
            return !title && title !== 0;
        };
        const handleVisibleChange = (val) => {
            const noTitle = isNoTitle();
            if (props.visible === undefined) {
                visible.value = noTitle ? false : val;
            }
            if (!noTitle) {
                emit('update:visible', val);
                emit('visibleChange', val);
            }
        };
        const getPopupDomNode = () => {
            return tooltip.value.getPopupDomNode();
        };
        expose({ getPopupDomNode, visible, forcePopupAlign: () => { var _a; return (_a = tooltip.value) === null || _a === void 0 ? void 0 : _a.forcePopupAlign(); } });
        const tooltipPlacements = computed(() => {
            const { builtinPlacements, arrowPointAtCenter, autoAdjustOverflow } = props;
            return (builtinPlacements ||
                getPlacements({
                    arrowPointAtCenter,
                    autoAdjustOverflow,
                }));
        });
        const isTrueProps = (val) => {
            return val || val === '';
        };
        const getDisabledCompatibleChildren = (ele) => {
            const elementType = ele.type;
            if (typeof elementType === 'object' && ele.props) {
                if (((elementType.__ANT_BUTTON === true || elementType === 'button') &&
                    isTrueProps(ele.props.disabled)) ||
                    (elementType.__ANT_SWITCH === true &&
                        (isTrueProps(ele.props.disabled) || isTrueProps(ele.props.loading)))) {
                    // Pick some layout related style properties up to span
                    // Prevent layout bugs like https://github.com/ant-design/ant-design/issues/5254
                    const { picked, omitted } = splitObject(getStyle(ele), [
                        'position',
                        'left',
                        'right',
                        'top',
                        'bottom',
                        'float',
                        'display',
                        'zIndex',
                    ]);
                    const spanStyle = Object.assign(Object.assign({ display: 'inline-block' }, picked), { cursor: 'not-allowed', width: ele.props && ele.props.block ? '100%' : null });
                    const buttonStyle = Object.assign(Object.assign({}, omitted), { pointerEvents: 'none' });
                    const child = cloneElement(ele, {
                        style: buttonStyle,
                    }, true);
                    return (<span style={spanStyle} class={`${prefixCls}-disabled-compatible-wrapper`}>
              {child}
            </span>);
                }
            }
            return ele;
        };
        const getOverlay = () => {
            var _a, _b;
            return (_a = props.title) !== null && _a !== void 0 ? _a : (_b = slots.title) === null || _b === void 0 ? void 0 : _b.call(slots);
        };
        const onPopupAlign = (domNode, align) => {
            const placements = tooltipPlacements.value;
            // 当前返回的位置
            const placement = Object.keys(placements).filter(key => placements[key].points[0] === align.points[0] &&
                placements[key].points[1] === align.points[1])[0];
            if (!placement) {
                return;
            }
            // 根据当前坐标设置动画点
            const rect = domNode.getBoundingClientRect();
            const transformOrigin = {
                top: '50%',
                left: '50%',
            };
            if (placement.indexOf('top') >= 0 || placement.indexOf('Bottom') >= 0) {
                transformOrigin.top = `${rect.height - align.offset[1]}px`;
            }
            else if (placement.indexOf('Top') >= 0 || placement.indexOf('bottom') >= 0) {
                transformOrigin.top = `${-align.offset[1]}px`;
            }
            if (placement.indexOf('left') >= 0 || placement.indexOf('Right') >= 0) {
                transformOrigin.left = `${rect.width - align.offset[0]}px`;
            }
            else if (placement.indexOf('right') >= 0 || placement.indexOf('Left') >= 0) {
                transformOrigin.left = `${-align.offset[0]}px`;
            }
            domNode.style.transformOrigin = `${transformOrigin.left} ${transformOrigin.top}`;
        };
        return () => {
            var _a, _b;
            const { openClassName, getPopupContainer, color, overlayClassName } = props;
            let children = (_b = filterEmpty((_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots))) !== null && _b !== void 0 ? _b : null;
            children = children.length === 1 ? children[0] : children;
            let tempVisible = visible.value;
            // Hide tooltip when there is no title
            if (props.visible === undefined && isNoTitle()) {
                tempVisible = false;
            }
            if (!children) {
                return null;
            }
            const child = getDisabledCompatibleChildren(isValidElement(children) ? children : <span>{children}</span>);
            const childCls = classNames({
                [openClassName || `${prefixCls.value}-open`]: true,
                [child.props && child.props.class]: child.props && child.props.class,
            });
            const customOverlayClassName = classNames(overlayClassName, {
                [`${prefixCls.value}-${color}`]: color && PresetColorRegex.test(color),
            });
            let formattedOverlayInnerStyle;
            let arrowContentStyle;
            if (color && !PresetColorRegex.test(color)) {
                formattedOverlayInnerStyle = { backgroundColor: color };
                arrowContentStyle = { backgroundColor: color };
            }
            const vcTooltipProps = Object.assign(Object.assign(Object.assign({}, attrs), props), { prefixCls: prefixCls.value, getTooltipContainer: getPopupContainer || getTargetContainer.value, builtinPlacements: tooltipPlacements.value, visible: tempVisible, ref: tooltip, overlayClassName: customOverlayClassName, overlayInnerStyle: formattedOverlayInnerStyle, onVisibleChange: handleVisibleChange, onPopupAlign });
            return (<VcTooltip {...vcTooltipProps} v-slots={{
                    arrowContent: () => (<span class={`${prefixCls.value}-arrow-content`} style={arrowContentStyle}></span>),
                    overlay: getOverlay,
                }}>
          {visible.value ? cloneElement(child, { class: childCls }) : child}
        </VcTooltip>);
        };
    },
});
