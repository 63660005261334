export function formatStrategyValues(pathKeys, keyPathEntities) {
    const valueSet = new Set(pathKeys);
    return pathKeys.filter(key => {
        const entity = keyPathEntities[key];
        const parent = entity ? entity.parent : null;
        if (parent && !parent.node.disabled && valueSet.has(parent.key)) {
            return false;
        }
        return true;
    });
}
export function toPathOptions(valueCells, options, fieldNames, 
// Used for loadingKeys which saved loaded keys as string
stringMode = false) {
    var _a;
    let currentList = options;
    const valueOptions = [];
    for (let i = 0; i < valueCells.length; i += 1) {
        const valueCell = valueCells[i];
        const foundIndex = currentList === null || currentList === void 0 ? void 0 : currentList.findIndex(option => {
            const val = option[fieldNames.value];
            return stringMode ? String(val) === String(valueCell) : val === valueCell;
        });
        const foundOption = foundIndex !== -1 ? currentList === null || currentList === void 0 ? void 0 : currentList[foundIndex] : null;
        valueOptions.push({
            value: (_a = foundOption === null || foundOption === void 0 ? void 0 : foundOption[fieldNames.value]) !== null && _a !== void 0 ? _a : valueCell,
            index: foundIndex,
            option: foundOption,
        });
        currentList = foundOption === null || foundOption === void 0 ? void 0 : foundOption[fieldNames.children];
    }
    return valueOptions;
}
